import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';
import { CarouselModel } from '../models/carousel';


@Injectable({
  providedIn: 'root'
})

export class CarouselService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwCarousel/GetDataTable', data);
  }

  public searchDataOrder(data: any, elements:number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwCarousel/GetOrderData/'  + elements, data);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwCarousel/ChangeOrder', data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwCarousel/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public get(id: string): Observable<CarouselModel> {
    return this.http.get<CarouselModel>(this.pathAPI + 'VwCarousel/' + id);
  }

  public GetVwCarouselLst(): Observable<CarouselModel[]> {
    return this.http.get<CarouselModel[]>(this.pathAPI + 'VwCarousel/GetVwCarouselLst/');
  }

  public saveImage(fileToUpload: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post<any>(this.pathAPI + 'VwCarousel/SaveCarouselImage', formData);
  }
  
  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwCarousel', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwCarousel/' + id, data);
  }  

  public trackCarousel(idCarousel: string): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwCarousel/TrackCarousel/' + idCarousel, null);
  }
}
