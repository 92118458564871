import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';
import { VwVideoTutorialDTO } from '../models/videotutorial';

@Injectable({
  providedIn: 'root'
})

export class VideoTutorialService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwVideoTutorial/GetDataTable', data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwVideoTutorial/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public searchDataOrder(data: any, elements:number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwVideoTutorial/GetOrderData/' + elements, data);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwVideoTutorial/ChangeOrder', data);
  }
  
  public getall(): Observable<VwVideoTutorialDTO[]> {
    return this.http.get<VwVideoTutorialDTO[]>(this.pathAPI + 'VwVideoTutorial/VwVideoTutorialAll');
  }

  public get(id: string): Observable<VwVideoTutorialDTO> {
    return this.http.get<VwVideoTutorialDTO>(this.pathAPI + 'VwVideoTutorial/' + id);
  }

  public saveDocumentFiles(fileDocument: File, fileImage:File): Observable<any> {
    let formData: FormData = new FormData();

    if (fileDocument != undefined) {
      formData.append('fileDocument', fileDocument, fileDocument.name);
    }

    if (fileImage != undefined) {
      formData.append('fileImage', fileImage, fileImage.name);
    }
    return this.http.post<any>(this.pathAPI + 'VwVideoTutorial/SaveDocumentFiles', formData);
  }

  public getDocumentFile(idDocument: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwVideoTutorial/GetDocumentFile/' + idDocument;
    return this.http.get<Blob>(endpoint, requestOptions);
  }

  public GetDocumentAsync(idDocument: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwVideoTutorial/GetDocumentAsync/' + idDocument;
    return this.http.get<Blob>(endpoint, requestOptions);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwVideoTutorial', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwVideoTutorial/' + id, data);
  }

  public trackVideoTutorial(id: string): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwVideoTutorial/TrackVideoTutorial/' + id, null);
  }
 
}
