import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';
import { FaqModel } from '../models/faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  private pathAPI = this.config.setting['PathAPIInfogroup'];

  constructor(private http: HttpClient, private config: AppConfig) {}

  public get(id: string): Observable<FaqModel> {
    return this.http.get<FaqModel>(this.pathAPI + 'VwFaq/' + id);
  }

  public getall(): Observable<FaqModel[]> {
    return this.http.get<FaqModel[]>(this.pathAPI + 'VwFaq/VwFaqAll');
  }

  public searchDataOrder(data: any, idBrand:number, elements:number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwFaq/GetOrderData/' + idBrand + '/' + elements, data);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwFaq/ChangeOrder', data);
  }

  public searchData(data: any, idBrand: number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwFaq/GetDataTable/' + idBrand, data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwFaq/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwFaq', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwFaq/' + id, data);
  }

}
