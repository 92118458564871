import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';
import { VwMenuDTO, VwMenuRootDTO } from '../models/menu';


@Injectable({
  providedIn: 'root'
})

export class MenuService {

  private pathAPI = this.config.setting['PathAPIInfogroup'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public get(id: string): Observable<VwMenuDTO> {
    return this.http.get<VwMenuDTO>(this.pathAPI + 'VwMenu/' + id);
  }


  public getRoot(): Observable<VwMenuRootDTO[]> {
    return this.http.get<VwMenuRootDTO[]>(this.pathAPI + 'VwMenu/VwMenuRoot');
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwMenu', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwMenu/' + id, data);
  }

  public updateTitle(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwMenu/UpdateTitle/' + id, data);
  }

  public searchData(data: any, idMenuParent: any): Observable<DataTablesResponse> {
    if (idMenuParent != null) {
      return this.http.post<DataTablesResponse>(this.pathAPI + 'VwMenu/GetDataTable?idMenuParent=' + idMenuParent, data);
    }
    else {
      return this.http.post<DataTablesResponse>(this.pathAPI + 'VwMenu/GetDataTable', data);
    }
  }

  public loadMenus(idMenuParent: any): Observable<any[]> {
    return this.http.get<any[]>(this.pathAPI + 'VwMenu/GetMenusRecursively?id=' + idMenuParent);
  }


  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwMenu/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public getMenuParent(id?: string): Observable<any> {
    let options = { responseType: 'text' as 'text' };
    if (id !== undefined && id !== null) {
      return this.http.get(this.pathAPI + 'VwMenu/GetVwMenuParent/' + id, options);
    }
    else {
      return this.http.get<any>(this.pathAPI + 'VwMenu/GetVwMenuParent');
    }
  }

  public getMenuDesc(id: string): Observable<any> {
    let options = { responseType: 'text' as 'text' };
    return this.http.get(this.pathAPI + 'VwMenu/GetVwMenuDesc/' + id, options);
  }

  public changeMenuOrder(data: any): any {
    return this.http.post<any>(this.pathAPI + 'VwMenu/ChangeVwMenuOrder', data);
  }


}
