import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';
import { SectionContentModel, SectionContentStyleModel, SectionContentTypeModel, SectionModel, SectionContentDataDocsModel, SectionHtmlDataModel } from '../models/section';
import { TreeViewModel } from '../models/tree-view';
import { VwDocumentDTO } from '../models/document';


@Injectable({
  providedIn: 'root'
})

export class SectionService {

  private pathAPI = this.config.setting['PathAPIInfogroup'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any, idBrand: number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwSection/GetDataTable/' + idBrand, data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwSection/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public get(id: string): Observable<SectionModel> {
    return this.http.get<SectionModel>(this.pathAPI + 'VwSection/' + id);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwSection', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwSection/' + id, data);
  }

  public getTreeViewMenu(lstBrand: number[]): Observable<TreeViewModel[]> {
    return this.http.get<TreeViewModel[]>(this.pathAPI + 'VwSection/GetTreeViewMenu/' + lstBrand.toString());
  }

  public getTreeViewMenuById(id: string): Observable<TreeViewModel[]> {
    return this.http.get<TreeViewModel[]>(this.pathAPI + 'VwSection/GetTreeViewMenuById/' + id);
  }

  public getSectionContentType(): Observable<SectionContentTypeModel[]> {
    return this.http.get<SectionContentTypeModel[]>(this.pathAPI + 'VwSection/GetVwSectionContentType');
  }

  public getSectionContentStyle(idContentType: number): Observable<SectionContentStyleModel[]> {
    return this.http.get<SectionContentStyleModel[]>(this.pathAPI + 'VwSection/GetVwSectionContentStyle/' + idContentType);
  }

/*SECTION CONTENT*/

  public postSectionContent(data: any): Observable<SectionContentModel> {
    return this.http.post<SectionContentModel>(this.pathAPI + 'VwSectionContent', data);
  }

  public putSectionContent(id: string,data: any): Observable<SectionContentModel> {
    return this.http.put<SectionContentModel>(this.pathAPI + 'VwSectionContent/' + id, data);
  }

  public updateSectionContentFilter(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwSectionContent/UpdateVwSectionContentFilters/' + id, data);
  }

  public changeStatusContent(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwSectionContent/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public updateContentName(id: string, name: string): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwSectionContent/UpdateContentName/' + id + '/?name=' + name, null);
  }

  public changeBlocked(id: string, isBlocked: boolean): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwSectionContent/ChangeBlocked/' + id + '/' + isBlocked, null);
  }

  public reorderSectionContent(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwSectionContent/ReorderSectionContent', data);
  }

/*SECTION CONTENT DOC*/

  public searchDocumentData(data: any, idSectionContent: string): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwSectionContentDataDoc/GetDocumentDataTable/' + idSectionContent, data);
  }

  public searchDocumentDataAdded(data: any, idSectionContent: string): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwSectionContentDataDoc/GetDocumentAddedDataTable/' + idSectionContent, data);
  }

  public postSectionContentDataDoc(data: any): Observable<SectionContentDataDocsModel> {
    return this.http.post<SectionContentDataDocsModel>(this.pathAPI + 'VwSectionContentDataDoc', data);
  }

  public deleteSectionContentDataDoc(idSectionContent:string, idDocument:string): Observable<any> {
    return this.http.delete<any>(this.pathAPI + 'VwSectionContentDataDoc/'+idSectionContent+'/'+idDocument);
  }

  public changeOrderSectionContentDataDoc(idSectionContent:string, data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwSectionContentDataDoc/ChangeVwSectionContentDataDocOrder/' + idSectionContent, data);
  }

  public getSectionContentDataDocuments(idSectionContent: string): Observable<VwDocumentDTO[]> {
    return this.http.get<VwDocumentDTO[]>(this.pathAPI + 'VwSectionContentDataDoc/GetDocuments/' + idSectionContent);
  }

/*SECTION CONTENT HTML*/

  public getSectionHtmlData(idSectionContent: string): Observable<SectionHtmlDataModel> {
    return this.http.get<SectionHtmlDataModel>(this.pathAPI + 'VwSectionContentDataHtml/GetSectionHtmlData/' + idSectionContent);
  }

  public saveSectionHtmlDataImage(fileToUpload: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('fileImage', fileToUpload, fileToUpload.name);
    return this.http.post<any>(this.pathAPI + 'VwSectionContentDataHtml/SaveImage', formData);
  }

  public getSectionHtmlDataImage(imageGuid: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + 'VwSectionContentDataHtml/GetImage/' + imageGuid;

    return this.http.get<Blob>(endpoint, requestOptions);
  }

  public postSectionContentDataHtml(data: any): Observable<SectionHtmlDataModel> {
    return this.http.post<SectionHtmlDataModel>(this.pathAPI + 'VwSectionContentDataHtml', data);
  }

  public putSectionContentDataHtml(id: string, data: any): Observable<SectionHtmlDataModel> {
    return this.http.put<SectionHtmlDataModel>(this.pathAPI + 'VwSectionContentDataHtml/' + id, data);
  }

  public postSectionNotification(idSection: string): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwSection/PostSectionNotification/' + idSection, null);
  }

  /*VISTA CONCESIONARIO*/
  public getSectionData(id: string, userEncript?: string): Observable<SectionModel> {
    if (userEncript == undefined) {
      return this.http.get<SectionModel>(this.pathAPI + 'VwSection/GetSectionData/' + id);
    }
    else {
      return this.http.get<SectionModel>(this.pathAPI + 'VwSection/GetSectionData/' + id + '?user=' + userEncript);
    }
    
  }

  public getSectionContentDataDocumentsUser(idSectionContent: string): Observable<VwDocumentDTO[]> {
    return this.http.get<VwDocumentDTO[]>(this.pathAPI + 'VwSectionContentDataDoc/GetDocumentsUser/' + idSectionContent);
  }

  public searchDocumentDataUser(data: any, idSectionContent: string): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwSectionContentDataDoc/GetDocumentDataTableUser/' + idSectionContent, data);
  }

  /*HOME*/
  public getSectionMostViewed(): Observable<SectionModel[]> {
    return this.http.get<SectionModel[]>(this.pathAPI + 'VwSection/GetMostViewedSections');
  }

}
