import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTablesResponse } from '../../models/common';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DealerMarketingPlanAudiService {
  controllerName = 'VwAdmpForm';

  constructor(private baseService: BaseService) {
  }

  getBasicInfo(year:string): Observable<any> {
    return this.baseService.get(`${this.controllerName}/GetBasicInfo/${year}`);
  }
  
  getForm(idForm:string): Observable<any> {
    return this.baseService.get(`${this.controllerName}/GetForm/${idForm}`);
  }

  getBasicInfoByIdForm(idForm:string, year:string): Observable<any> {
    return this.baseService.get(`${this.controllerName}/GetBasicInfoByIdFormAndYear/${idForm}/${year}`);
  }

  saveDealerMarketingPlan(dealerMarketingPlan: any): Observable<any> {
    return this.baseService.post(`${this.controllerName}/SetAdmp`, dealerMarketingPlan);
  }

  editDealerMarketingPlan(dealerMarketingPlan: any): Observable<any> {
    return this.baseService.put(`${this.controllerName}/EditForm`, dealerMarketingPlan);
  }

  getYearsByDealer(): Observable<any> {
    return this.baseService.get(`${this.controllerName}/GetYearsByDealer`);
  }

  getListAdmpByYear(year:number): Observable<any> {
    return this.baseService.get(`${this.controllerName}/GetListAdmpByYear/${year}`);
  }
  
  public searchDataClient(data: any, idOrganization: number, year: number): Observable<DataTablesResponse> {
    return this.baseService.post<DataTablesResponse>(this.controllerName + '/GetListAdmpByYearDealer/' + idOrganization + '/' + year, data);
  }

  downloadDealerMarketingPlanListExcel(idOrganization: number, year: number): Observable<any> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    return this.baseService.download<Blob>(`${this.controllerName}/GetAdminExcel/${idOrganization}/${year}`, requestOptions);
  }
}
