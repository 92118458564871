import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';
import { BannerModel } from '../models/banner';


@Injectable({
  providedIn: 'root'
})

export class BannerService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwBanners/GetDataTable', data);
  }

  public searchDataOrder(data: any, elements:number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwBanners/GetOrderData/' + elements, data);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwBanners/ChangeOrder', data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwBanners/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public get(id: string): Observable<BannerModel> {
    return this.http.get<BannerModel>(this.pathAPI + 'VwBanners/' + id);
  }

  public GetVwBannersLst(): Observable<BannerModel[]> {
    return this.http.get<BannerModel[]>(this.pathAPI + 'VwBanners/GetVwBannersLst/');
  }

  public saveImage(fileToUpload: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post<any>(this.pathAPI + 'VwBanners/SaveBannerImage', formData);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwBanners', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwBanners/' + id, data);
  }
  
  public trackBanner(idBanner: string): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwBanners/TrackBanner/' + idBanner, null);
  }
}
