import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { UploadFileResult } from '../../models/upload-file-result';
import { UploadResult } from '../../models/upload-result';
import { AppConfig } from '../../config/config';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];
 
  private chunkSize = 104857600;

  constructor(private httpClient: HttpClient, private config: AppConfig) { }

  private static getErrorResult(reason: any): UploadFileResult {
    return {
      Result: {
        Succeeded: false,
        ErrorMessage: reason,
        FileGuid: '',
        FileName: ''
      },
      TmpDirectoryName: ''
    };
  }

  public upload(file: File): Observable<UploadResult> {
    return new Observable<UploadResult>(observer => {
      if (file != undefined) {
        const fileName = file.name;
        this.sendBufferDataFile(file, fileName, observer);
      }
      else {
        var result: UploadResult = {
          Succeeded: true,
          ErrorMessage: '',
          FileGuid: '',
          FileName: ''
        }
        observer.next(result);
        observer.complete();
      }
    });
  }

  private async sendBufferDataFile(file: File,
    fileName: string,
    observer: Observer<UploadResult>) {
    if (file == null) {
      observer.error('failed reading file data');
      return;
    }    
    await this.startSendingData(fileName)
      .then(
        result => this.sendChunksFile(file, result.TmpDirectoryName)
      ).then(result => this.endSendingData(
        fileName,
        result.Result.FileName,
        result.TmpDirectoryName
      ))
      .then(result => {
        if (result.Succeeded) {
          observer.next(result);
          observer.complete();
        } else {
          observer.error(result);
        }
      })
      .catch(reason => observer.error(reason));
  }


  private startSendingData(fileName: string): Promise<UploadFileResult> {
    return new Promise<UploadFileResult>(((resolve, reject) => {
      const formData = new FormData();
      formData.append('fileName', fileName);
      this.httpClient.post<UploadFileResult>(
        this.pathAPI + 'File/StartUploadingFile',
        formData, {})
        .toPromise()
        .then(result => {
          if (result.Result.Succeeded) {
            resolve(result);
          } else {
            reject(result);
          }
        })
        .catch(reason => reject(FileUploaderService.getErrorResult(reason)));
    }));
  }

  private async sendChunksFile(file: File,
    tmpDirectoryName: string): Promise<UploadFileResult> {
    return new Promise<UploadFileResult>(async (resolve, reject) => {
      let fileIndex = 0;
      const sendChunkPromises = new Array<Promise<UploadResult>>();
      for (let i = 0; i < file.size; i += this.chunkSize) {
        let indexTo = i + this.chunkSize;
        if (indexTo >= file.size) {
          indexTo = file.size; // for last data.
        }
        const formData = new FormData();
        formData.append('file', file.slice(i, indexTo));
        const promise = this.httpClient.post<UploadResult>(
          this.pathAPI + 'File/UploadChunkFile',
          formData,
          {
            headers: {
              tmpDirectory: tmpDirectoryName,
              index: fileIndex.toString()
            }
          })
          .toPromise();
        sendChunkPromises.push(promise);
        fileIndex += 1;
      }
       
      var blockIds: string[] = [];
      var succeded = true;

      var docLoaded = 1;

      for (const fn of sendChunkPromises) {            
        await fn.then(x => {
          if (x.Succeeded) {
            blockIds.push(x.FileName);
            let porc = (docLoaded / sendChunkPromises.length) * 100;
            $('#pbdocs').attr('aria-valuenow', porc).css('width', porc + '%');   
            docLoaded++;            
          }
          else {
            succeded = false;
          }          
        }).catch(reason => reject(FileUploaderService.getErrorResult(reason)));
      }

      resolve({
        Result: {
          Succeeded: succeded,
          ErrorMessage: '',
          FileGuid: '',
          FileName: blockIds.toString()
        },
        TmpDirectoryName: tmpDirectoryName
      });
      
    });
  }

  private endSendingData(fileName: string, blockIds: string, tmpDirectoryName: string): Promise<UploadResult> {
    return new Promise<UploadResult>(((resolve, reject) => {
      const formData = new FormData();
      formData.append('filename', fileName);
      formData.append('blobkIds', blockIds);
      formData.append('tmpDirectory', tmpDirectoryName);
      this.httpClient.post<UploadResult>(
        this.pathAPI + 'File/EndUploadingFile',
        formData, {})
        .toPromise()
        .then(result => {
          if (result.Succeeded) {
            resolve(result);
          } else {
            reject(result);
          }
        })
        .catch(reason => reject(FileUploaderService.getErrorResult(reason).Result));
    }));
  }
}
