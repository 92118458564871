import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { MaterialModel } from '../models/material';
import { ChangeOrderModel, DataTablesResponse } from '../../models/common';

@Injectable({
  providedIn: 'root'
})

export class MaterialService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwMaterial/GetDataTable', data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwMaterial/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public searchDataOrder(data: any, elements:number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwMaterial/GetOrderData/' + elements, data);
  }

  public changeOrder(data: ChangeOrderModel[]): any {
    return this.http.post<any>(this.pathAPI + 'VwMaterial/ChangeOrder', data);
  }

  public get(id: string): Observable<MaterialModel> {
    return this.http.get<MaterialModel>(this.pathAPI + 'VwMaterial/' + id);
  }

  public saveMaterialImage(fileToUpload: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post<any>(this.pathAPI + 'VwMaterial/SaveMaterialImage', formData);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwMaterial', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwMaterial/' + id, data);
  }

  public getMaterialListHome(): Observable<MaterialModel[]> {
    return this.http.get<MaterialModel[]>(this.pathAPI + 'VwMaterial/GetMaterialHome');
  }

}
