import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/config';
import { DataTablesResponse } from '../../models/common';
import { NewsModel } from '../models/news';

@Injectable({
  providedIn: 'root'
})

export class NewsService {

  private pathAPI = this.config.setting['PathAPIEMSeat'];

  constructor(private http: HttpClient, private config: AppConfig) { }

  public searchData(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + 'VwNews/GetDataTable', data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwNews/ChangeStatus/' + id + '/' + idStatus, null);
  }

  public get(id: string): Observable<NewsModel> {
    return this.http.get<NewsModel>(this.pathAPI + 'VwNews/' + id);
  }

  public saveNewsImage(fileToUpload: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post<any>(this.pathAPI + 'VwNews/SaveNewsImage', formData);
  }

  public post(data: any): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwNews', data);
  }

  public put(id: string, data: any): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'VwNews/' + id, data);
  }

  public GetNewsListHome(): Observable<NewsModel[]> {
    return this.http.get<NewsModel[]>(this.pathAPI + 'VwNews/GetVwNewsHome');
  }

  public trackNews(idNews: string): Observable<any> {
    return this.http.post<any>(this.pathAPI + 'VwNews/TrackNews/' + idNews, null);
  }

}
